import ThemisInput from "@/components/shared/input"

export default {
  name: "AddPreCondition",
  data() {
    return {
      ruleSelected   : null,
      operatorType   : "equals",
      optionsSelected: []
    }
  },
  components: {
    ThemisInput
  },
  props: {
    pGroups: Array,
    pRoles : Array
  },
  methods: {
    onSubmit() {
      const rules = [{
        key  : "verify",
        value: this.ruleSelected
      }, {
        key  : "checkValue",
        value: this.optionsSelected
      }]
      this.$emit("submit", rules)
    },
    handleCancel() {
      this.$emit("cancel")
    }
  },
  computed: {
    workflowsHelpCenterUrl() {
      return process.env.VUE_APP_WORKFLOWS_HELP_URL
    },
    ruleItems() {
      return [{
        text : this.$t("1467"),
        value: "report.status"
      }, {
        text : this.$t("1901"),
        value: "groupId"
      }, {
        text : this.$t("1907"),
        value: "roleId"
      }]
    },
    ruleOperatorTypes() {
      return [{
        text : this.$t("1468"),
        value: "equals"
      }]
    },
    ruleOptions() {
      this.optionsSelected = []
      if (this.ruleSelected === "report.status") {
        return [{
          text : this.$t("1469"),
          value: "closed"
        }]
      }
      if (this.ruleSelected === "groupId") {
        return this.pGroups.map(group => ({
          text : group.name,
          value: group.id
        }))
      }
      if (this.ruleSelected === "roleId") {
        return this.pRoles
          .filter(role => role.issue)
          .map(role => ({
            text : role.name,
            value: role.id
          }))
      }
      return []
    }
  }
}