import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES,
  POST_FUNCTIONS_NAMES,
  POST_FUNCTION_RULES,
  POST_FUNCTION_FIELDS
} from "@/constants"
import TransitionAddRule from "@/components/transition/add-rule"

export default {
  name: "TransitionPostFunctions",
  data() {
    return {
      showAddRuleDialog   : false,
      showDeleteRuleDialog: false,
      ruleToDelete        : {}
    }
  },
  components: {
    TransitionAddRule
  },
  props: {
    pSystemDefault          : Boolean,
    pCanAddNewRule          : Boolean,
    pPostFunctions          : Array,
    pTransitionPostFunctions: Array
  },
  computed: {
    headersForPostFunctionsTable() {
      return getHeadersForTable(TABLE_NAMES.TRANSITION_POST_FUNCTIONS, this.$t.bind(this))
    },
    footersForPostFunctionsTable() {
      return getFootersForTable(TABLE_NAMES.TRANSITION_POST_FUNCTIONS, this.$t.bind(this))
    },
    itemsForPostFunctionsTable() {
      return this.pTransitionPostFunctions.map(postFunction => {
        let ruleName               = ""
        const postFunctionKeys     = Object.keys(postFunction.value)[0].split(".")
        const postFunctionValue    = Object.values(postFunction.value)[0]
        const postFunctionConstant = this.getPostFunction(postFunctionKeys, postFunction.type)
        if (postFunctionConstant.value === postFunctionValue) {
          ruleName = this.$t(postFunctionConstant.label)
        }
        return {
          id   : postFunction.id,
          ruleName,
          type : postFunction.type,
          value: postFunction.value
        }
      })
    },
    rules() {
      return POST_FUNCTION_RULES.map(rule => {
        if (rule.items.length) {
          return {
            ...rule,
            items: rule.items.map(item => ({
              text : this.$t(item.text),
              value: item.value
            }))
          }
        } else {
          return {
            ...rule,
            items: this.pPostFunctions.map(postFunction => {
              const key = Object.keys(postFunction.value)[0]
              return {
                text : this.$t(POST_FUNCTION_FIELDS[postFunction.type][key]),
                value: key
              }
            })
          }
        }
      })
    }
  },
  methods: {
    getPostFunction(postFunctionKeys, type) {
      return postFunctionKeys.reduce((acc, key) => {
        return acc[key]
      }, POST_FUNCTIONS_NAMES[type])
    },
    addRule(rule) {
      let ruleToBeAdded
      this.showAddRuleDialog = false

      const key                = rule.find(eachRule => eachRule.key === "field").value
      const performActionValue = rule.find(eachRule => eachRule.key === "performAction").value
      if (performActionValue === "clear") {
        ruleToBeAdded = {
          [key]: null
        }
      }

      const isRuleExistsInDatabase = this.pPostFunctions.find(postFunction => {
        return JSON.stringify(postFunction.value) === JSON.stringify(ruleToBeAdded) && postFunction.type === "set field"
      })

      if (isRuleExistsInDatabase) {
        const isRuleAlreadyExistsInTransition = this.pTransitionPostFunctions.some(postFunction => {
          return JSON.stringify(postFunction.value) === JSON.stringify(ruleToBeAdded) && postFunction.type === "set field"
        })

        if (!isRuleAlreadyExistsInTransition) {
          const postFunctionRules = [
            ...this.pTransitionPostFunctions,
            {
              id           : isRuleExistsInDatabase.id,
              type         : "set field",
              value        : ruleToBeAdded,
              systemDefault: isRuleExistsInDatabase.systemDefault
            }
          ]
          this.$emit("updateRules", postFunctionRules)
        }
      }
    },
    showDialogToConfirmDeleteRule(rule) {
      this.showDeleteRuleDialog = true
      this.ruleToDelete         = rule
    },
    handleCancelDeleteRule() {
      this.showDeleteRuleDialog = false
      this.ruleToDelete         = {}
    },
    handleDeleteRuleConfirmed() {
      this.showDeleteRuleDialog = false
      const postFunctionRules   = this.pTransitionPostFunctions.filter(postFunction =>
        !(postFunction.type === this.ruleToDelete.type &&
          JSON.stringify(postFunction.value) === JSON.stringify(this.ruleToDelete.value))
      )
      this.$emit("updateRules", postFunctionRules)
      this.ruleToDelete = {}
    }
  }
}