import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES, PRE_CONDITIONS_RULES } from "@/constants"
import ThemisAddPreCondition from "@/components/transition/add-pre-condition"

export default {
  name: "TransitionPreConditions",
  data() {
    return {
      showAddRuleDialog   : false,
      showDeleteRuleDialog: false,
      ruleToDelete        : {}
    }
  },
  components: {
    ThemisAddPreCondition
  },
  props: {
    pTransitionPreConditions         : Array,
    pPreConditions                   : Array,
    pInitialTransition               : Boolean,
    pIsTransitionToStatusDoneCategory: Boolean,
    pSystemDefault                   : Boolean,
    pGroups                          : Array,
    pRoles                           : Array
  },
  computed: {
    headersForPreConditionsTable() {
      return getHeadersForTable(TABLE_NAMES.TRANSITION_PRE_CONDITIONS, this.$t.bind(this))
    },
    footersForPreConditionsTable() {
      return getFootersForTable(TABLE_NAMES.TRANSITION_PRE_CONDITIONS, this.$t.bind(this))
    },
    groupsMap() {
      const groupsMap = {}
      for (const group of this.pGroups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    rolesMap() {
      const rolesMap = {}
      for (const role of this.pRoles) {
        rolesMap[role.id] = role
      }
      return rolesMap
    },
    itemsForPreConditionsTable() {
      return this.pTransitionPreConditions.map(preCondition => {
        const [key, value] = Object.entries(preCondition.value)[0]
        if (key === "report.status") {
          return {
            id               : preCondition.id,
            ruleName         : this.$t("1148"),
            preConditionValue: preCondition.value,
            type             : preCondition.type,
            disabledButton   : this.pIsTransitionToStatusDoneCategory && value === "closed",
            key,
            value
          }
        } else if (key === "groupId") {
          let groupNames = value.map(groupId => {
            return this.groupsMap[groupId]?.name
          })
          if (groupNames.length > 1) {
            groupNames = groupNames.join(` ${this.$t("1906")} `)
          }
          return {
            id               : preCondition.id,
            ruleName         : this.$t("1903", { groupNames }),
            type             : preCondition.type,
            preConditionValue: preCondition.value,
            disabledButton   : false,
            key,
            value
          }
        } else if (key === "roleId") {
          let roleNames = value.map(roleId => {
            return this.rolesMap[roleId]?.name
          })
          if (roleNames.length > 1) {
            roleNames = roleNames.join(` ${this.$t("1906")} `)
          }
          return {
            id               : preCondition.id,
            ruleName         : this.$t("1908", { roleNames }),
            type             : preCondition.type,
            preConditionValue: preCondition.value,
            disabledButton   : false,
            key,
            value
          }
        }
      })
    },
    rules() {
      return PRE_CONDITIONS_RULES.map(rule => {
        if (rule.items.length) {
          return {
            ...rule,
            items: rule.items.map(item => ({
              text : this.$t(item.text),
              value: item.value
            }))
          }
        }
      })
    }
  },
  methods: {
    addRule(rule) {
      let preConditionRules  = []
      this.showAddRuleDialog = false

      const key        = rule.find(eachRule => eachRule.key === "verify").value
      const checkValue = rule.find(eachRule => eachRule.key === "checkValue").value

      const groupIds = this.pTransitionPreConditions
        .filter(preCondition => "groupId" in preCondition.value)
        .flatMap(preCondition => preCondition.value.groupId)

      const roleIds = this.pTransitionPreConditions
        .filter(preCondition => "roleId" in preCondition.value)
        .flatMap(preCondition => preCondition.value.roleId)

      const ruleToBeAdded = {
        [key]: checkValue
      }

      if (key === "groupId" && checkValue.every(groupId => groupIds.includes(groupId)) ||
        key === "roleId" && checkValue.every(roleId => roleIds.includes(roleId))) {
        return
      }

      const isRuleExistsInDatabase = this.pPreConditions.find(preCondition => {
        return JSON.stringify(preCondition.value) === JSON.stringify(ruleToBeAdded) && preCondition.type === "check field"
      })

      if (isRuleExistsInDatabase && key === "report.status") {
        const isRuleAlreadyExistsInTransition = this.pTransitionPreConditions.some(preCondition => {
          return JSON.stringify(preCondition.value) === JSON.stringify(ruleToBeAdded) && preCondition.type === "check field"
        })
        if (!isRuleAlreadyExistsInTransition) {
          preConditionRules = [
            ...this.pTransitionPreConditions, {
              id           : isRuleExistsInDatabase.id,
              type         : "check field",
              value        : ruleToBeAdded,
              systemDefault: isRuleExistsInDatabase.systemDefault
            }
          ]
        }
      } else {
        preConditionRules = [
          ...this.pTransitionPreConditions, {
            type         : "check field",
            value        : ruleToBeAdded,
            systemDefault: false
          }
        ]
      }
      if (preConditionRules.length) {
        this.$emit("updateRules", preConditionRules)
      }
    },
    showDialogToConfirmDeleteRule(rule) {
      this.showDeleteRuleDialog = true
      this.ruleToDelete         = rule
    },
    handleCancelDeleteRule() {
      this.showDeleteRuleDialog = false
      this.ruleToDelete         = {}
    },
    handleDeleteRuleConfirmed() {
      this.showDeleteRuleDialog = false
      const preConditionRules   = this.pTransitionPreConditions.filter(preCondition =>
        !(preCondition.type === this.ruleToDelete.type &&
        JSON.stringify(preCondition.value) === JSON.stringify(this.ruleToDelete.preConditionValue))
      )
      this.$emit("updateRules", preConditionRules)
      this.ruleToDelete = {}
    }
  }
}