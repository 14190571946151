export default {
  name: "Alert",
  data: () => ({
    labelClass   : "",
    inputFocussed: false
  }),
  props: {
    pMessage: String,
    pYes    : Object,
    pNo     : Object,
    pColor  : String
  },
  methods: {
  }
}