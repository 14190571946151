import ThemisInput from "@/components/shared/input"

export default {
  data() {
    return {
      localRules: []
    }
  },
  props: {
    pDynamicRuleName: String,
    pRules          : Array
  },
  components: {
    ThemisInput
  },
  methods: {
    getRule(rule) {
      const rules = []
      if (rule.mandatory) {
        rules.push("required")
      }
      return rules.join("|")
    },
    getRuleLabel(rule) {
      const name = this.getRuleName(rule)
      return rule.mandatory ? `${name}*` : name
    },
    getRuleName(rule) {
      return this.$t(rule.label)
    },
    onSubmit() {
      this.$emit("submit", this.localRules),
      this.localRules = []
    },
    handleCancel() {
      this.$emit("cancel"),
      this.localRules = []
    }
  },
  computed: {
    workflowsHelpCenterUrl() {
      return process.env.VUE_APP_WORKFLOWS_HELP_URL
    }
  },
  watch: {
    pRules: {
      immediate: true,
      handler  : function() {
        this.localRules = [...this.pRules].map(rule => {
          return {
            key        : rule.key,
            value      : rule.items.length === 1 ? rule.items[0].value : undefined,
            items      : rule.items,
            mandatory  : rule.mandatory,
            label      : rule.label,
            placeholder: this.$t(rule.placeholder)
          }
        })
      }
    }
  }
}