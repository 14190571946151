export default {
  name: "WorkflowNavigateAwayDialog",
  data: () => ({
  }),
  props: {
    pShowDialog: Boolean,
    pWorkflow  : Object
  },
  computed: {
  },
  methods: {
    handleContinueWithoutSaving() {
      this.$emit("continueWithoutSaving")
    },
    handleNavigateToWorkflow() {
      this.$emit("closeDialog")
    }
  }
}