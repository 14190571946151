import ThemisInput from "@/components/shared/input"
import ThemisTransitionPostFunctions from "@/components/transition/post-functions"
import ThemisTransitionPreConditions from "@/components/transition/pre-conditions"
import ThemisAlert from "@/components/shared/alert"
import ThemisDecision from "@/components/shared/decision"
import ThemisReadOnly from "@/components/shared/read-only"
import ThemisWorkflowNavigateAway from "@/components/workflow/navigate-away"
import { mapActions, mapGetters } from "vuex"
import { getFromLocalStorage, saveToLocalStorage } from "@/utils"
import {
  ANCHOR_LINKS,
  MAX_CHARACTER_LIMIT,
  REPORT_STATUS,
  ISSUE_STATUS_CATEGORY,
  ARRAY,
  WORKFLOW_ROUTES
} from "@/constants"

export default {
  name      : "Transition",
  components: {
    ThemisInput,
    ThemisTransitionPostFunctions,
    ThemisTransitionPreConditions,
    ThemisWorkflowNavigateAway,
    ThemisAlert,
    ThemisDecision,
    ThemisReadOnly
  },
  data() {
    return {
      transitionTab   : null,
      workflows       : getFromLocalStorage("workflows"),
      changedWorkflows: getFromLocalStorage("changedWorkflows"),
      tabs            : {
        [ANCHOR_LINKS.PRE_CONDITIONS]: 0,
        [ANCHOR_LINKS.POST_FUNCTIONS]: 1
      },
      isTransitionNameDuplicate   : false,
      transitionNameCharacterLimit: MAX_CHARACTER_LIMIT.TRANSITION_NAME,
      localTransition             : {
        id        : null,
        name      : null,
        fromStatus: [],
        toStatus  : null,
        screen    : {}
      },
      oldTransitionName               : null,
      showTransitionDeleteDialog      : false,
      showWorkflowUnsavedChangesDialog: false,
      destinationRoute                : null,
      isNavigationAllowed             : false
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!WORKFLOW_ROUTES.includes(to.name) &&
      this.changedWorkflows && this.changedWorkflows[this.workflow.id]) {
      this.destinationRoute = to
      if (!this.isNavigationAllowed) {
        this.showWorkflowUnsavedChangesDialog = true
        return
      }
    }
    next()
  },
  computed: {
    ...mapGetters({
      workflowsFromStore: "workflows/workflows",
      screens           : "screens/screens",
      postFunctions     : "postFunctions/postFunctions",
      preConditions     : "preConditions/preConditions",
      groups            : "groups/groups",
      roles             : "roles/roles"
    }),
    isTransitionToStatusDoneCategory() {
      return this.localTransition.toStatus.category === ISSUE_STATUS_CATEGORY.DONE
    },
    workflow() {
      return this.workflows.find(workflow => workflow.id === +this.$route.params.id)
    },
    changedWorkflow() {
      return this.changedWorkflows && this.changedWorkflows[this.workflow.id]
    },
    isTransitionChanged() {
      const transitionUpdateProperty = this.changedWorkflow?.transition?.update
      const transitionAddProperty    = this.changedWorkflow?.transition?.add

      if (transitionUpdateProperty || transitionAddProperty) {
        const transitionInUpdate = transitionUpdateProperty?.find(transition =>
          transition.id === this.transition?.id || transition.name === this.transition?.name)

        const transitionInAdd = transitionAddProperty?.find(transition =>
          transition.name === this.transition?.name)

        return !!(transitionInUpdate || transitionInAdd)
      } else {
        return false
      }
    },
    propsForAlertYes() {
      return {
        text: this.$t("1452")
      }
    },
    transition() {
      return this.workflow.transitions.find(transition =>
        transition.name === this.$route.query.transitionName
      )
    },
    initialTransition() {
      return this.transition?.initialTransition
    },
    screen() {
      return this.transition?.screen
    },
    screensToDisplay() {
      return [{
        id  : null,
        name: this.$t("1032")
      }, ...this.screens]
    },
    transitionPreConditions() {
      return this.transition?.preConditions ?? []
    },
    transitionPostFunctions() {
      return this.transition?.postFunctions ?? []
    },
    systemDefault() {
      return this.workflow.systemDefault
    },
    statusesMap() {
      const statusesMap = {}
      for (const status of this.workflow.statuses) {
        if (status.id) {
          statusesMap[status.id] = status
        } else {
          statusesMap[status.name] = status
        }
      }
      return statusesMap
    },
    hasFromAllStatus() {
      return !!this.transition?.transitionLinks.some(transitionLink =>
        transitionLink.fromStatusId === null
      )
    },
    readOnlyFromStatusText() {
      return this.localTransition.fromStatus.map(item => item.name).join(", ")
    },
    fromStatusesToDisplay() {
      if (this.hasFromAllStatus) {
        return [{
          name: this.$t("1138")
        }]
      }
      const workflowsFromStore  = this.workflowsFromStore.find(workflow => workflow.id === this.workflow.id)
      const transitionFromStore = workflowsFromStore?.transitions.find(transition =>
        transition.name === this.transition?.name)
      const statuses            = this.transition?.transitionLinks
        .map(transitionLink => ({
          id              : this.statusesMap[transitionLink.fromStatusId].id,
          name            : this.statusesMap[transitionLink.fromStatusId].name,
          category        : this.statusesMap[transitionLink.fromStatusId].category,
          transitionLinkId: transitionLink.id
        }))
      for (const workflowStatus of this.workflow.statuses) {
        const existingTransition = transitionFromStore?.transitionLinks.find(transitionLink =>
          transitionLink.fromStatusId === workflowStatus.id ||
          transitionLink.fromStatusId === workflowStatus.name
        )
        if (statuses && !statuses.find(status => status.name === workflowStatus.name)) {
          statuses.push({
            id              : workflowStatus.id,
            name            : workflowStatus.name,
            category        : workflowStatus.category,
            transitionLinkId: existingTransition?.id
          })
        }
      }
      if (statuses?.length) {
        return statuses.filter(status =>
          status.name !== this.localTransition.toStatus?.name)
      }

      return statuses
    },
    toStatusesToDisplay() {
      if (this.localTransition.fromStatus.length) {
        if (this.initialTransition) {
          return this.workflow.statuses.filter(status =>
            status.category !== ISSUE_STATUS_CATEGORY.DONE
          )
        } else {
          return this.workflow.statuses.filter(status =>
            !this.localTransition.fromStatus.some(fromStatus => fromStatus.name === status.name)
          )
        }
      } else {
        return this.workflow.statuses
      }
    },
    hasFromStatusChanged() {
      const localFromStatusIds          = this.localTransition.fromStatus.map(status => status.id)
      const transitionLinkFromStatusIds = this.transition.transitionLinks.map(transitionLink =>
        transitionLink.fromStatusId
      )

      if (localFromStatusIds.length !== transitionLinkFromStatusIds.length) {
        return true
      }

      for (const statusId of localFromStatusIds) {
        if (!transitionLinkFromStatusIds.includes(statusId)) {
          return true
        }
      }

      return false
    },
    isToStatusFieldReadOnly() {
      return this.workflow.systemDefault || (this.hasFromAllStatus && !this.initialTransition)
    },
    isTransitionNameReadonly() {
      return this.workflow.systemDefault || this.hasFromAllStatus || this.initialTransition
    }
  },
  methods: {
    ...mapActions({
      revertChangedWorkflow: "workflows/revertChangedWorkflow",
      revertWorkflow       : "workflows/revertWorkflow"
    }),
    updateRules(newRules, key) {
      const updatedWorkflows = this.workflows.map(workflow => {
        if (workflow.id === this.workflow.id) {
          workflow.transitions = workflow.transitions?.map(transition => {
            if (transition.name === this.transition.name) {
              transition[key] = [...newRules]
            }
            return transition
          })
        }
        return workflow
      })

      saveToLocalStorage("workflows", updatedWorkflows)
      this.workflows = updatedWorkflows

      const changedWorkflows = getFromLocalStorage("changedWorkflows") || {}
      let changedWorkflow    = changedWorkflows?.[this.workflow.id]

      const rulesToBeAdded    = newRules.map(rule => ({
        id           : rule.id,
        type         : rule.type,
        value        : JSON.stringify(rule.value),
        systemDefault: rule.systemDefault || false
      }))
      const changedTransition = {
        id   : this.localTransition.id,
        [key]: rulesToBeAdded
      }

      if (changedWorkflow) {
        if (changedWorkflow.transition) {
          const transitionToUpdate = changedWorkflow.transition.update
          const transitionToAdd    = changedWorkflow.transition.add

          if (transitionToAdd) {
            const transitionIndex = transitionToAdd.findIndex(transition =>
              transition.name === this.localTransition.name)
            if (transitionIndex >= 0) {
              transitionToAdd[transitionIndex][key] = rulesToBeAdded
            }
          }

          if (this.localTransition.id) {
            // existing transition with id
            if (transitionToUpdate) {
              // update the existing transition
              const transitionIndex = transitionToUpdate.findIndex(transition =>
                transition.id === this.localTransition.id)
              if (transitionIndex >= 0) {
                // if that transition is edited for second time
                transitionToUpdate[transitionIndex][key] = rulesToBeAdded
              } else {
                transitionToUpdate.push(changedTransition)
              }
            } else {
              changedWorkflow.transition = {
                ...changedWorkflow.transition,
                update: [changedTransition]
              }
            }
          }
        } else {
          // when transition property is not present in the changedWorkflow object for current workflow
          changedWorkflow = {
            ...changedWorkflow,
            transition: {
              update: [changedTransition]
            }
          }
        }
      } else {
        // when changedWorkflow is empty and we are changing the transition
        changedWorkflow = {
          transition: {
            update: [changedTransition]
          }
        }
      }
      changedWorkflows[this.workflow.id] = changedWorkflow
      saveToLocalStorage("changedWorkflows", changedWorkflows)
      this.changedWorkflows = changedWorkflows
    },
    handleTabClickEvent(hash) {
      this.transitionTab = this.tabs[hash] || this.tabs[ANCHOR_LINKS.PRE_CONDITIONS]
      if (this.$route.hash !== hash) {
        this.$router.push({
          hash,
          query: {
            transitionName: this.transition?.name
          }
        })
      }
    },
    handleYesForAlert() {
      this.$router.push({
        name  : "workflow",
        params: {
          id: this.workflow.id
        }
      })
    },
    handleTransitionNameChange(onBlur, errors) {
      onBlur()
      const oldTransitionName        = this.transition.name
      const isAnExistingTransition   = this.workflow.transitions.find(transition =>
        transition.name === this.localTransition.name)
      this.isTransitionNameDuplicate = !!(isAnExistingTransition && this.localTransition.name !== this.transition?.name)

      if (this.localTransition.name?.length && this.localTransition.name !== this.transition?.name &&
        !isAnExistingTransition && !errors.length) {
        const updatedWorkflows = this.workflows.map(workflow => {
          if (workflow.id === this.workflow.id) {
            workflow.transitions = workflow.transitions?.map(transition => {
              if (transition.name === oldTransitionName) {
                transition.name = this.localTransition.name
              }
              return transition
            })
          }
          return workflow
        })
        saveToLocalStorage("workflows", updatedWorkflows)
        this.workflows = updatedWorkflows
        this.$router.replace({
          query: {
            transitionName: this.localTransition.name
          }
        })

        const changedWorkflows = getFromLocalStorage("changedWorkflows") || {}
        let changedWorkflow    = changedWorkflows?.[this.workflow.id]

        const changedTransition = {
          id  : this.localTransition.id,
          name: this.localTransition.name
        }

        if (changedWorkflow) {
          if (changedWorkflow.transition) {
            const transitionToAdd    = changedWorkflow.transition.add
            const transitionToUpdate = changedWorkflow.transition.update

            if (transitionToAdd) {
              // when new transition is added
              const indexOfExistingTransition = transitionToAdd.findIndex(transition =>
                transition.name === oldTransitionName
              )
              if (indexOfExistingTransition >= 0) {
                transitionToAdd[indexOfExistingTransition].name = this.localTransition.name
              }
            }

            // existing transition with id
            if (this.localTransition.id) {
              if (transitionToUpdate) {
                const indexOfTransitionToBeUpdated = transitionToUpdate.findIndex(
                  transition => transition.id === this.localTransition.id
                )
                if (indexOfTransitionToBeUpdated >= 0) {
                  transitionToUpdate[indexOfTransitionToBeUpdated].name = this.localTransition.name
                } else {
                  transitionToUpdate.push(changedTransition)
                }
              } else {
                changedWorkflow.transition = {
                  ...changedWorkflow.transition,
                  update: [changedTransition]
                }
              }
            }
          } else {
            // when transition property is not present in the changedWorkflow object for current workflow
            changedWorkflow = {
              ...changedWorkflow,
              transition: {
                update: [changedTransition]
              }
            }
          }
        } else {
          // when changedWorkflow is empty and we are changing the transition
          changedWorkflow = {
            transition: {
              update: [changedTransition]
            }
          }
        }
        changedWorkflows[this.workflow.id] = changedWorkflow
        saveToLocalStorage("changedWorkflows", changedWorkflows)
        this.changedWorkflows = changedWorkflows

        saveToLocalStorage("workflows", updatedWorkflows)
        this.workflows = updatedWorkflows
      }
    },
    handleTransitionFromStatusChange(onBlur, errors) {
      onBlur()
      if (!errors.length && !this.hasFromAllStatus && this.hasFromStatusChanged) {
        const updatedWorkflows = this.workflows.map(workflow => {
          if (workflow.id === this.workflow.id) {
            workflow.transitions = workflow.transitions?.map(transition => {
              if (transition.name === this.transition.name) {
                transition.transitionLinks = this.localTransition.fromStatus.map(fromStatus => ({
                  id          : fromStatus.transitionLinkId,
                  fromStatusId: fromStatus.name === this.$t("1138") ? null : (fromStatus.id ?? fromStatus.name),
                  toStatusId  : this.localTransition.toStatus.id ?? this.localTransition.toStatus.name
                }))
              }
              return transition
            })
          }
          return workflow
        })
        saveToLocalStorage("workflows", updatedWorkflows)
        this.workflows = updatedWorkflows

        this.handleUpdatedFromAndToStatusInChangedWorkflows()
      }
    },
    handleTransitionToStatusChange(onBlur) {
      onBlur()
      const oldTransitionToStatusId = this.transition?.transitionLinks[0].toStatusId
      if (this.localTransition.toStatus && oldTransitionToStatusId && (
        oldTransitionToStatusId !== this.localTransition.toStatus.id ||
        (typeof oldTransitionToStatusId === "string" &&
          oldTransitionToStatusId !== this.localTransition.toStatus.name))) {

        const updatedWorkflows = this.workflows.map(workflow => {
          if (workflow.id === this.workflow.id) {
            workflow.transitions = workflow.transitions?.map(transition => {
              if (transition.name === this.transition.name) {
                transition.transitionLinks.map(transitionLink => {
                  if (transitionLink.toStatusId === oldTransitionToStatusId) {
                    transitionLink.toStatusId = this.localTransition.toStatus.id ?? this.localTransition.toStatus.name
                  }
                  return transitionLink
                })
                if (transition.preConditions?.length) {
                  transition.preConditions = transition.preConditions
                    .map(preCondition => {
                      if ("report.status" in preCondition.value
                        && preCondition.value["report.status"] === REPORT_STATUS.CLOSED
                        && this.localTransition.toStatus.category !== ISSUE_STATUS_CATEGORY.DONE) {

                        // if pre condition report must be closed has been manually added to the transition
                        // then don't remove it from workflows if to status category is not done
                        const hasTransitionPropertyInChangedWorkflow =
                          this.changedWorkflows?.[this.workflow.id]?.transition
                        if (hasTransitionPropertyInChangedWorkflow) {
                          const hasAddProperty    = hasTransitionPropertyInChangedWorkflow.add
                          const hasUpdateProperty = hasTransitionPropertyInChangedWorkflow.update

                          let currentTransition
                          if (hasAddProperty) {
                            currentTransition = hasAddProperty.find(transition =>
                              transition.name === this.localTransition.name)
                          }

                          if (!currentTransition && hasUpdateProperty) {
                            currentTransition = hasUpdateProperty.find(transition =>
                              transition.id === this.localTransition.id)
                          }

                          if (currentTransition) {
                            const hasPreCondition = currentTransition.preConditions?.find(transitionPreCondition => {
                              const parsedPreConditionValue = JSON.parse(transitionPreCondition.value)
                              return "report.status" in parsedPreConditionValue && parsedPreConditionValue["report.status"] === REPORT_STATUS.CLOSED
                            })

                            if (hasPreCondition) {
                              return preCondition
                            }
                          }
                        }

                        // return null to mark this element for removal
                        return null
                      }
                      return preCondition
                    })
                    .filter(condition => condition !== null)

                  // add pre-condition report must be closed if toStatus is done and pre-condition does not have report.status
                  const hasToAddPreConditionReportMustBeClosed = !transition.preConditions.some(preCondition =>
                    "report.status" in preCondition.value && preCondition.value["report.status"] === REPORT_STATUS.CLOSED) &&
                    this.localTransition.toStatus.category === ISSUE_STATUS_CATEGORY.DONE

                  if (hasToAddPreConditionReportMustBeClosed) {
                    transition.preConditions.push({
                      type : "check field",
                      value: {
                        "report.status": REPORT_STATUS.CLOSED
                      }
                    })
                  }
                } else if (this.localTransition.toStatus.category === ISSUE_STATUS_CATEGORY.DONE) {
                  return {
                    ...transition,
                    preConditions: [{
                      type : "check field",
                      value: {
                        "report.status": REPORT_STATUS.CLOSED
                      }
                    }]
                  }
                }
              }
              return transition
            })
          }
          return workflow
        })
        saveToLocalStorage("workflows", updatedWorkflows)
        this.workflows = updatedWorkflows

        this.handleUpdatedFromAndToStatusInChangedWorkflows()
      }
    },
    handleUpdatedFromAndToStatusInChangedWorkflows() {
      const changedWorkflows              = getFromLocalStorage("changedWorkflows") || {}
      let changedWorkflow                 = changedWorkflows?.[this.workflow.id]
      const toStatus                      = this.localTransition.toStatus.id ?? this.localTransition.toStatus.name
      const transitionLinksToAddAndUpdate = this.localTransition.fromStatus.map(fromStatus => ({
        id          : fromStatus.transitionLinkId,
        fromStatusId: fromStatus.name === this.$t("1138") ? null : (fromStatus.id ?? fromStatus.name),
        toStatusId  : toStatus
      }))

      let transitionLinkToAddIndex
      const newTransition = {
        id             : this.localTransition.id,
        transitionLinks: transitionLinksToAddAndUpdate
      }

      if (changedWorkflow) {
        if (changedWorkflow.transition) {
          const transitionToAdd    = changedWorkflow.transition?.add
          const transitionToUpdate = changedWorkflow.transition?.update

          if (transitionToAdd) {
            transitionLinkToAddIndex = changedWorkflow.transition.add.findIndex(transition =>
              transition.name === this.localTransition.name
            )
            if (transitionLinkToAddIndex >= 0) {
              changedWorkflow.transition.add[transitionLinkToAddIndex] = {
                ...changedWorkflow.transition.add[transitionLinkToAddIndex],
                transitionLinks: transitionLinksToAddAndUpdate
              }
            }
          }

          if (this.localTransition.id) {
            if (transitionToUpdate) {
              // update the existing transition
              const indexOfTransitionToBeUpdated = transitionToUpdate.findIndex(
                transition => transition.id === this.localTransition.id
              )
              if (indexOfTransitionToBeUpdated >= 0) {
                // if that transition is edited for second time
                transitionToUpdate[indexOfTransitionToBeUpdated].transitionLinks = transitionLinksToAddAndUpdate
              } else {
                transitionToUpdate.push(newTransition)
              }
            } else {
              changedWorkflow.transition = {
                ...changedWorkflow.transition,
                update: [newTransition]
              }
            }
          }
        } else {
          // when transition property is not present in the changedWorkflow object for current workflow
          changedWorkflow = {
            ...changedWorkflow,
            transition: {
              update: [newTransition]
            }
          }
        }
      } else {
        // when changedWorkflow is empty and we are changing the transition
        changedWorkflow = {
          transition: {
            update: [newTransition]
          }
        }
      }
      changedWorkflows[this.workflow.id] = changedWorkflow
      saveToLocalStorage("changedWorkflows", changedWorkflows)
      this.changedWorkflows = changedWorkflows
    },
    handleTransitionDelete() {
      this.showTransitionDeleteDialog = false

      const localTransitionId = this.localTransition.id
      const changedWorkflows  = getFromLocalStorage("changedWorkflows") || {}
      let changedWorkflow     = changedWorkflows?.[this.workflow.id]

      if (changedWorkflow) {
        if (changedWorkflow.transition) {
          if (localTransitionId) {
            if (!changedWorkflow.transition.delete) {
              changedWorkflow.transition.delete = []
            }
            if (!changedWorkflow.transition.delete.includes(localTransitionId)) {
              changedWorkflow.transition.delete.push(localTransitionId)
            }
          }

          const transitionAddProperty    = changedWorkflow?.transition?.add
          const transitionUpdateProperty = changedWorkflow?.transition?.update
          const transitionDeleteProperty = changedWorkflow?.transition?.delete

          if (transitionAddProperty) {
            if (transitionAddProperty.length === 1 &&
            transitionAddProperty[ARRAY.FIRST].name === this.localTransition.name) {
              delete changedWorkflow.transition.add
            } else {
              changedWorkflow.transition.add = transitionAddProperty
                .filter(transition => transition.name !== this.localTransition.name)
            }
          }

          if (transitionUpdateProperty) {
            if (transitionUpdateProperty.length === 1 &&
            transitionUpdateProperty[ARRAY.FIRST].id === localTransitionId) {
              delete changedWorkflow.transition.update
            } else {
              changedWorkflow.transition.update = transitionUpdateProperty
                .filter(transition => transition.id !== localTransitionId)
            }
          }

          // Delete the 'delete' property if empty
          if (transitionDeleteProperty && transitionDeleteProperty.length === 0) {
            delete changedWorkflow.transition.delete
          }
        } else {
          if (localTransitionId) {
            changedWorkflow = {
              ...changedWorkflow,
              transition: {
                delete: [localTransitionId]
              }
            }
          }
        }
      } else {
        // when changedWorkflow is empty and we are changing the transition
        changedWorkflow = {
          transition: {
            delete: [localTransitionId]
          }
        }
      }

      if (changedWorkflow.transition &&
        Object.keys(changedWorkflow.transition).length === 0) {
        // when transition payload in changedWorkflow is empty
        if (Object.keys(changedWorkflow).length === 1) {
          // when transition is the only key in changedWorkflow
          delete changedWorkflows[this.workflow.id]
        } else {
          delete changedWorkflow.transition
        }
      } else {
        changedWorkflows[this.workflow.id] = changedWorkflow
      }

      const workflowTransitionDelete = this.workflows.map(workflow => {
        if (workflow.id === this.workflow.id) {
          workflow.transitions = workflow.transitions.filter(transition =>
            transition.name !== this.localTransition.name)
        }
        return workflow
      })
      saveToLocalStorage("workflows", workflowTransitionDelete)
      this.workflows = workflowTransitionDelete

      saveToLocalStorage("changedWorkflows", changedWorkflows)
      this.changedWorkflows = changedWorkflows
      this.$router.push({
        name  : "workflow-status-side-panel",
        params: {
          id: this.workflow.id
        },
        query: {
          status: this.$route.query.status
        }
      })
    },
    handleScreenChange(onBlur) {
      onBlur()
      // if screen is not selected, then set it to No screen
      if (!this.localTransition.screen) {
        this.localTransition.screen = {
          id: null, name: this.$t("1032")
        }
      }
      let oldTransitionScreenId
      const updatedWorkflows = this.workflows.map(workflow => {
        if (workflow.id === this.workflow.id) {
          workflow.transitions = workflow.transitions?.map(transition => {
            if (transition.name === this.transition.name) {
              oldTransitionScreenId = transition.screenId
              transition.screen     = {
                id  : this.localTransition.screen.id,
                name: this.localTransition.screen.name
              }
              transition.screenId   = this.localTransition.screen.id
            }
            return transition
          })
        }
        return workflow
      })
      saveToLocalStorage("workflows", updatedWorkflows)
      this.workflows = updatedWorkflows

      if (oldTransitionScreenId !== this.localTransition.screen.id) {
        // update changedWorkflows only if screen is changed
        const changedWorkflows  = getFromLocalStorage("changedWorkflows") || {}
        let changedWorkflow     = changedWorkflows?.[this.workflow.id]
        const changedTransition = {
          id      : this.localTransition.id,
          screenId: this.localTransition.screen.id
        }

        if (changedWorkflow) {
          if (changedWorkflow.transition) {
            const transitionToUpdate = changedWorkflow.transition.update
            const transitionToAdd    = changedWorkflow.transition.add

            if (transitionToAdd) {
              const transitionIndex = transitionToAdd.findIndex(transition =>
                transition.name === this.localTransition.name)
              if (transitionIndex >= 0) {
                transitionToAdd[transitionIndex].screenId = changedTransition.screenId
              }
            }

            if (this.localTransition.id) {
              // existing transition with id
              if (transitionToUpdate) {
                const transitionIndex = transitionToUpdate.findIndex(transition =>
                  transition.id === this.localTransition.id)
                if (transitionIndex >= 0) {
                  transitionToUpdate[transitionIndex].screenId = changedTransition.screenId
                } else {
                  transitionToUpdate.push(changedTransition)
                }
              } else {
                changedWorkflow.transition = {
                  ...changedWorkflow.transition,
                  update: [changedTransition]
                }
              }
            }
          } else {
            // when transition property is not present in the changedWorkflow object for current workflow
            changedWorkflow = {
              ...changedWorkflow,
              transition: {
                update: [changedTransition]
              }
            }
          }
        } else {
        // when changedWorkflow is empty and we are changing the transition
          changedWorkflow = {
            transition: {
              update: [changedTransition]
            }
          }
        }
        changedWorkflows[this.workflow.id] = changedWorkflow
        saveToLocalStorage("changedWorkflows", changedWorkflows)
        this.changedWorkflows = changedWorkflows
      }
    },
    async deleteChangedWorkflow() {
      const changedWorkflows = await this.revertChangedWorkflow({ id: this.workflow?.id })
      this.changedWorkflows  = changedWorkflows
    },
    async revertWorkflowChange() {
      await this.deleteChangedWorkflow()
      const updatedWorkflows =
      await this.revertWorkflow({ id: this.workflow?.id })
      this.workflows         = updatedWorkflows
    },
    handleContinueWithoutSaving() {
      this.showWorkflowUnsavedChangesDialog = false
      this.isNavigationAllowed              = true
      this.revertWorkflowChange()
    },
    handleNavigateToWorkflow() {
      this.$router.push({
        name  : "workflow",
        params: {
          id: this.workflow.id
        }
      })
    }
  },
  watch: {
    "$route.hash": {
      immediate: true,
      handler  : function(hash) {
        if (hash) {
          this.handleTabClickEvent(hash)
        }
      }
    },
    transition: {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if (newValue && (newValue?.name !== oldValue?.name)) {
          this.localTransition.id   = newValue.id
          this.localTransition.name = newValue.name
          if (this.hasFromAllStatus) {
            this.localTransition.fromStatus = [{
              name            : this.$t("1138"),
              transitionLinkId: newValue.transitionLinks[0].id
            }]
          } else {
            this.localTransition.fromStatus = newValue.transitionLinks.map(transitionLink => {
              const status = this.statusesMap[transitionLink.fromStatusId]
              return {
                id              : status.id,
                name            : status.name,
                category        : status.category,
                transitionLinkId: transitionLink.id
              }
            })
          }
          const toStatus                = this.statusesMap[newValue.transitionLinks[0].toStatusId]
          this.localTransition.toStatus = {
            id      : toStatus.id,
            name    : toStatus.name,
            category: toStatus.category
          }
          const screen                  = this.transition?.screen || {}
          this.localTransition.screen   = {
            id  : screen.id ? screen.id : null,
            name: screen.name ? screen.name : this.$t("1032")
          }
        }
      }
    },
    "localTransition.name": {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && this.isTransitionNameDuplicate) {
          this.isTransitionNameDuplicate = false
        }
      }
    },
    isNavigationAllowed: {
      handler: function(newValue) {
        if (newValue) {
          this.$router.push(this.destinationRoute)
        }
      }
    }
  }
}