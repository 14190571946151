var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"data-cy":"4800"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"data-cy":"4801"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card',{staticClass:"pa-2",attrs:{"data-cy":"4802"}},[_c('v-card-title',{staticClass:"grey--text text--darken-4 font-weight-regular",attrs:{"data-cy":"4803"}},[_c('v-row',{attrs:{"data-cy":"4804"}},[_c('v-col',{staticClass:"col-md-auto",attrs:{"data-cy":"4805"}},[_c('span',{ref:"rule_name",attrs:{"data-cy":"4807"}},[_vm._v(_vm._s(_vm.$t("1399"))+" ")]),_vm._v(_vm._s(_vm.$t("1480")))]),_c('v-col',{staticClass:"text-end",attrs:{"data-cy":"4808"}},[_c('v-icon',{ref:"button_close_add_rule",attrs:{"data-cy":"4809","color":"grey","medium":""},on:{"click":_vm.handleCancel}},[_vm._v("mdi-close")])],1)],1)],1),_c('v-list-item',{attrs:{"data-cy":"4810"}},[_c('v-list-item-content',{attrs:{"data-cy":"4811"}},[_c('span',{ref:"rule_description",staticClass:"mb-6 grey--text text--darken-4",attrs:{"data-cy":"4812"}},[_vm._v(_vm._s(_vm.$t("1398"))+" "),_c('a',{ref:"link_workflow_help_center",staticClass:"link secondary--text",staticStyle:{"text-decoration":"none"},attrs:{"data-cy":"4813","href":_vm.workflowsHelpCenterUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("1421")))])]),_c('themis-input',{ref:"themis_input_rule_name",attrs:{"data-cy":"4814","p-label-class":"secondary--text","p-label":_vm._f("required")(("" + (_vm.$t('1464')))),"p-rules":"required","p-name":"1464"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"rule_type",attrs:{"data-cy":"4815","item-text":"text","item-value":"value","color":"secondary","items":_vm.ruleItems,"placeholder":_vm.$t('1902'),"error-messages":errors,"outlined":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.ruleSelected),callback:function ($$v) {_vm.ruleSelected=$$v},expression:"ruleSelected"}})]}}],null,true)}),_c('themis-input',{ref:"themis_input_rule_operator",attrs:{"data-cy":"4816","p-label-class":"secondary--text","p-label":_vm._f("required")(("" + (_vm.$t('1465')))),"p-rules":"required","p-name":"1465"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-select',{ref:"rule_operator",attrs:{"data-cy":"4817","item-text":"text","item-value":"value","color":"secondary","items":_vm.ruleOperatorTypes,"error-messages":errors,"outlined":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.operatorType),callback:function ($$v) {_vm.operatorType=$$v},expression:"operatorType"}})]}}],null,true)}),_c('themis-input',{ref:"themis_input_rule_options",attrs:{"data-cy":"4818","p-label-class":"secondary--text","p-label":_vm._f("required")(("" + (_vm.$t('1466')))),"p-rules":"required","p-name":"1466"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [(_vm.ruleOptions.length === 1)?_c('v-select',{ref:"rule_options",attrs:{"data-cy":"4819","item-text":"text","item-value":"value","color":"secondary","items":_vm.ruleOptions,"placeholder":_vm.$t('1905'),"error-messages":errors,"outlined":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.optionsSelected),callback:function ($$v) {_vm.optionsSelected=$$v},expression:"optionsSelected"}}):_c('v-autocomplete',{ref:"rule_options",attrs:{"data-cy":"4820","item-text":"text","item-value":"value","placeholder":_vm.$t('1905'),"color":"secondary","items":_vm.ruleOptions,"error-messages":errors,"multiple":"","outlined":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.optionsSelected),callback:function ($$v) {_vm.optionsSelected=$$v},expression:"optionsSelected"}})]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"justify-end mr-2",attrs:{"data-cy":"4821"}},[_c('v-btn',{ref:"button_cancel",staticClass:"font-weight-bold",attrs:{"data-cy":"4822","text":""},on:{"click":_vm.handleCancel}},[_vm._v(_vm._s(_vm.$t("1436")))]),_c('v-btn',{ref:"button_submit",staticClass:"ml-6 px-4",attrs:{"data-cy":"4823","color":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("1397")))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }